import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
    fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import { Polly } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";
import config from "../../../../config/config";

//Parser
const parser = new DOMParser()
// Set the parameters
const speechParams = {
    OutputFormat: "mp3",
    SampleRate: "16000",
    Text: "",
    TextType: "ssml", // Changed from "text" to "ssml"
    VoiceId: "Joey" // For example, "Matthew"
};

const client = new Polly({
    region: `${config.defaults.aws_region}`,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: `${config.defaults.aws_region}` }),
        identityPoolId: `${config.defaults.aws_region}:${config.defaults.aws_poolId}` // IDENTITY_POOL_ID
    }),
});


const speakText = async (text: string, isMath: boolean = false) => {

    const numberPattern = /\d/;
    const numberPatternTest = numberPattern.test(text);

    // Parse HTML and get plain text
    let plainText = parser.parseFromString(text, "text/html").body.textContent || "";

    console.log(isMath, 'check');
    if (numberPatternTest && isMath) {
        console.log('regexing');
        const pattern = /[-\d\s]+/;
        const res = pattern.test(plainText);

        const pattern2 = /-/g;
        const res2 = pattern2.test(plainText);

        if (res && res2) {
            plainText = plainText.replace(/-/g, 'minus');
        }

        const pattern3 = /[–\d\s]+/;
        const res3 = pattern3.test(plainText);

        const pattern4 = /–/g;
        const res4 = pattern4.test(plainText);

        if (res3 && res4) {
            plainText = plainText.replace(/–/g, 'minus');
        }
    }

    // Handle punctuation marks with SSML - convert periods and question marks
    plainText = plainText
    .replace(/\./g, '<break time="300ms"/> <sub alias="period">.</sub> <break time="400ms"/>')
    // .replace(/,/g, '<break time="200ms"/> <sub alias="comma">,</sub> <break time="200ms"/>')
    .replace(/\?/g, '<break time="300ms"/> <sub alias="question mark">?</sub> <break time="400ms"/>');

    
    // Wrap the text in SSML speak tags
    speechParams.Text = `<speak>${plainText}</speak>`;

    try {
        let url = await getSynthesizeSpeechUrl({
            client, params: speechParams
        });

        return url;
    } catch (err) {
        console.log("Error", err);
        return "";
    }
};


export default speakText;