/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactChild,
  createContext,
  FC,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  getQuestions,
  updateQuestions,
  getAssessmentsAction,
  setSelectedAssessment,
  saveFormativeObjective,
  saveSummativeObjective,
} from "redux/actionCreators/cmsAssessment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { RootState } from "redux/reducers/combine";
import { getMarkings } from "util/RaphaelInit";
import { getCalculator, setCalculator } from "../PreviewAssessment/Footer";
import { AppCookies } from "util/constants";


export interface question {
  id: number;
  passage_directions: string;
  passage_1: string;
  passage_2: string;
  direction: string;
  question: string;
  choice_1: string;
  choice_2: string;
  choice_3: string;
  choice_4: string;
  answer: number;
  objective_id: number;
  showTabs: boolean;
  updated: boolean;
  questionNo: number;
  shares_passage_with: number;
  penMarkings: any;
  isScreenMarked: boolean;
  containerDOM: any;
  grouped_with_question: number;
  updated_question: boolean;
  updated_grouping: boolean;
  hasPassage1: boolean;
  hasPassage2: boolean;
  variant: string
}

interface groupedObject {
  parent: number;
  children: number[];
  idx: number;
  lastIdx: number;
  questionNumber: number[];
}

interface QuestionnaireContextModel {
  audioRef: any;
  getCalculatorElement:()=>any;
  closeCalculator:()=>void;
  destroyCalculator: ()=>void;
  setIsSpeechIconClicked: (a: boolean) => void;
  isSpeechIconClicked: boolean;
  hasPassage: boolean;
  showTabs: boolean;
  questionIdx: number;
  setQuestionIdx: (idx: number) => void;
  questions: question[];
  currentQuestion: question;
  nextQuestion: () => void;
  previousQuestion: () => void;
  hasNextQuestion: boolean;
  hasPreviousQuestion: boolean;
  isTestUpdated: boolean;
  onRevert: () => Promise<void>;
  onSave: () => Promise<any>;
  sharePassageList: question[];
  toggleHasPassage: (hasPassage: boolean) => void;
  editKey: string;
  setEditKey: (key: string) => void;
  onSaveEditor: (text: string) => void;
  onChange: (key: string, value: any) => void;
  show: boolean;
  setShow: (value: boolean) => void;
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
  objectiveName: string;
  onChangeObjective: (objectiveId: number) => void;
  showSaveModel: boolean;
  setShowSaveModel: (showModel: boolean) => void;
  showBackModal: boolean;
  setBackModal: (showModal: boolean) => void;
  answerUpdated: boolean;
  onAnswerChange: (choice: number) => void;
  updatedQuestions: number;
  setIsPencilActive: (pencilActive: boolean) => void;
  isPencilActive: boolean;
  showCalculator: boolean;
  setShowCalculator: (showCalcultor: boolean) => void;
  isHighlighterActive: boolean;
  setIsHighlighterActive: (isHighlighter: boolean) => void;
  keyToSpeak: string;
  setKeyToSpeak: (key: string) => void;
  keys: string[];
  onSpeak: (key: string) => void;
  isPreview: boolean;
  setPreview: Function;
  onChangeQuestionFromTopBar: (questionNumber: number, variant?:string, id?:number) => void;
  setIsScreenMarked: (value: boolean) => void;
  containerRef: any;
  groupedDataForQuestion?: groupedObject;
  groupedList: question[];
  disableDropdown: boolean;
  removeGrouping: (data: groupedObject, flag: boolean) => void;
  setIsSave: (save: boolean) => void;
  isSave: boolean;
  groupedWithQuestion: number;
  groupedPassageDataForQuestion?: groupedObject;
  joinArrayWithMaxElements: (array: any[], element: number) => void;
  disablePassageDropdown: boolean;
  showGroupRemoveWarningModal: boolean;
  setShowGroupRemoveWarningModal: (showModel: boolean) => void;
  setTriggerPassageRemoveGroupingFor: (flag: number) => void;
  triggerPassageRemoveGroupingFor: number; // 1 has_passage, 2 remove_group
  sharesPassageWith: number;
  grade: number;
  setShowHighlightEraser: (cond: boolean) => void;
  showHighlightEraser: boolean;
  setIsExpand: (expand: boolean) => void;
  isExpand: boolean;
  assesmentVariant: string;
  setAssesmentVariant: (variant: string) => void;
  variants: string[];  
  isApiError: boolean
  setIsApiError: (error: boolean)=> void
}

export const QuestionnaireContext = createContext(
  {} as QuestionnaireContextModel
);

interface Props {
  children: ReactChild | ReactChild[];
}

const questionKeys = [
  "passage_directions",
  "direction",
  "question",
  "choice_1",
  "choice_2",
  "choice_3",
  "choice_4",
];

const QuestionnaireContextProvider: FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { assessmentType, id, questionId } = useParams();

  const { assessments } = useSelector(
    (state: RootState) => state.cmsAssessment
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [questions, setQuestions] = useState<question[]>([]);
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [editKey, setEditKey] = useState<string>(null!);
  const [showHighlightEraser, setShowHighlightEraser] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [show, setShow] = useState(false);
  const [grade, setGrade] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [showSaveModel, setShowSaveModel] = useState(false);
  const [showBackModal, setBackModal] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [answerUpdated, setAnswerUpdated] = useState(false);
  const [isPencilActive, setIsPencilActive] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [isHighlighterActive, setIsHighlighterActive] = useState(false);
  const [isSpeechIconClicked, setIsSpeechIconClicked] = useState(false);
  const [keyToSpeak, setKeyToSpeak] = useState<string>(null!);
  const [isPreview, setPreview] = useState<boolean>(false);
  const { allObjectives } = useSelector((state: RootState) => state.objectives);
  const [variants, setVariants] = useState<string[]>([]);
  const [assesmentVariant, setAssesmentVariant] = useState<string>("A");
  const [isApiError, setIsApiError] = useState<boolean>(false);
  
  const audioRef=useRef<any>();
  const [groupedQuestionData, setGroupedQuestionData] = useState<
    groupedObject[]
  >([]);
  const [groupedList, setGroupedList] = useState<question[]>([]);
  const [groupedDataForQuestion, setGroupedDataForQuestion] = useState<
    groupedObject | undefined
  >();
  const [disableDropdown, setDisableDropdown] = useState(false);
  const [groupedWithQuestion, setGroupedWithQuestion] = useState(-1);

  const [groupedPassageQuestionData, setGroupedPassageQuestionData] = useState<
    groupedObject[]
  >([]);
  const [groupedPassageDataForQuestion, setGroupedPassageDataForQuestion] =
    useState<groupedObject | undefined>();
  const [sharePassageList, setSharePassageList] = useState<question[]>([]);
  const [disablePassageDropdown, setDisablePassageDropdown] = useState(false);
  const [sharesPassageWith, setSharesPassageWith] = useState(-1);

  const [showGroupRemoveWarningModal, setShowGroupRemoveWarningModal] =
    useState(false);
  const [triggerPassageRemoveGroupingFor, setTriggerPassageRemoveGroupingFor] =
    useState(0);

  const [keys, setKeys] = useState<string[]>([...questionKeys]);
  const currentQuestion: any = useMemo(() => {
    const currentQuestion = { ...questions[questionIdx] } || {};

    if (currentQuestion.shares_passage_with) {
      const sharedQuestion = questions.find(
        (qes: any) => qes.id === currentQuestion.shares_passage_with
      );
      if (sharedQuestion) {
        currentQuestion["passage_directions"] =
          sharedQuestion.passage_directions;
        currentQuestion["passage_1"] = sharedQuestion.passage_1;
        currentQuestion["passage_2"] = sharedQuestion.passage_2;
        currentQuestion["hasPassage1"] = !!sharedQuestion.passage_1;
        currentQuestion["hasPassage2"] = !!sharedQuestion.passage_2
      }
    }
    return currentQuestion;
  }, [questionIdx, questions]);

  const ayncFetchQuestions = async (
    assessmentType: string,
    assessmentId: string
  ) => {
    const { questions, objective_id, grade, variants } = await getQuestions(
      assessmentType,
      parseInt(assessmentId)
    );
    grade && !Number.isNaN(grade) && setGrade(+grade);
    variants?.length && setVariants(variants);
    const updatedQuestions = questions.map((q: any, index: number) => {
      const currentQuestion = {
        ...q,
        questionNo: index + 1,
        objective_id:
          assessmentType === "formatives" ? objective_id : q.objective_id,
        penMarkings: [],
        isScreenMarked: false,
        hasPassage1: !!q.passage_1,
        hasPassage2: !!q.passage_2,
        passage_directions: !!q.passage_1 ? q.passage_directions : ""
      };

      return currentQuestion;
    });



    await setQuestions(updatedQuestions);
    await updateGrouping(updatedQuestions);
    await updatePassageGrouping(updatedQuestions);
  };

  useEffect(() => {
    let element: HTMLElement | null = null;
    console.log('keyToSpeak', keyToSpeak);

    const scrollElement =  keyToSpeak &&
    ["passage_1", "passage_2"].some((passage) => keyToSpeak.includes(passage));

    if (scrollElement)
     {
      element = document.getElementById(keyToSpeak);
      if (element) {
        element.classList.add("playtts");
        element.scrollIntoView({behavior:"smooth", block:"center"}); 
      }
    }

    if (keyToSpeak ==="passage_directions" || scrollElement){
      element = document.getElementById(keyToSpeak);
      element?.scrollIntoView({behavior:"smooth", block: "center"});
    }

    return () => {
      if (element) {
        element.classList.remove("playtts");
      }
    };
  }, [keyToSpeak]);

  //calles when questions changes
  useEffect(() => {

    const element = document.getElementById(keyToSpeak);
    element?.classList.remove("playtts"); 
    setTabIndex(0);
    setIsPencilActive(false);
    setIsHighlighterActive(false);
    updateGrouping(questions);
    updatePassageGrouping(questions);
    setShowHighlightEraser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if(audioRef.current?.paused===false){
      audioRef.current?.pause();
      setKeyToSpeak(null!)
  }
  }, [questionIdx]);

  useEffect(() => {
    const passageId = `passage_${tabIndex + 1}`;

    const passageNodes = document
      .getElementById(passageId)
      ?.querySelectorAll("p, li, span, td");

    const passageKeys: string[] = [];
    passageNodes?.forEach((node: any, idx) => {

      const hasAncestorInList = (node: any) => {
        let current = node.parentElement;
        while (current) {
          if (Array.from(passageNodes).includes(current)) {
            return true;
          }
          current = current.parentElement;
        }
        return false;
      };
       
      if (hasAncestorInList(node)) {
        return;
      }
 
      const passageKey = `${passageId}_${idx + 1}`;
      node.id = passageKey;
      currentQuestion[passageKey] = node.innerText;
      passageKeys.push(passageKey);
      node.onclick = () => isSpeechIconClicked && setKeyToSpeak(passageKey);
    });

    const newKeys = [...questionKeys];

    newKeys.splice(1, 0, ...passageKeys);

    setKeys(newKeys);

    return () => {
      const passageNodes = document
        .getElementById(passageId)
        ?.querySelectorAll("p");

      passageNodes?.forEach((node: any) => {
        node.onclick = () => false;
      });
      setKeys([...questionKeys]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion.id, tabIndex, isSpeechIconClicked]);

  useEffect(() => {
    if (questionId) {
      const parsedQuestionId = parseInt(questionId);
      const index = questions.findIndex(
        (question: any) => question.id === parsedQuestionId
      );
      index > -1 && setQuestionIdx(index);
    }
  }, [questions, questionId]);

  useEffect(() => {
    if (assessmentType && id) {
      ayncFetchQuestions(assessmentType, id);
    } else navigate("/cms/select-assessment");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, assessmentType, id, navigate]);

  useEffect(() => {
    const grade_id = Cookies.get(AppCookies.grade_id);
    const subject_id = Cookies.get(AppCookies.subject_id);

    if (grade_id && subject_id) {
      dispatch(getAssessmentsAction(parseInt(grade_id), parseInt(subject_id)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (assessments.length > 0 && id && assessmentType) {
      const selectedAssessment = assessments.find(
        (assessment: any) =>
          assessment.key === parseInt(id) && assessment.type === assessmentType
      );
      if (selectedAssessment) {
        const assessmentName =
          (assessmentType === "formatives"
            ? selectedAssessment.objective_number + " -"
            : "") + selectedAssessment.name;
        dispatch(setSelectedAssessment(assessmentName));
      }
    }
  }, [dispatch, assessments, id, assessmentType]);

  useEffect(() => {
    setGroupedWithQuestion(questions[questionIdx]?.grouped_with_question || -1);

    let groupedListData: question[] = [];

    if (questionIdx !== 0 && groupedQuestionData) {
      let checkForGrouping = groupedQuestionData.find((group) => {
        return (
          group.lastIdx === questionIdx + 1 ||
          group.children.includes(questions[questionIdx].id)
        );
      });

      if (!checkForGrouping) {
        checkForGrouping = groupedQuestionData.find((group) => {
          return group.lastIdx === questionIdx;
        });
      }

      if (!!checkForGrouping) {
        if (
          groupedWithQuestion === -1 &&
          questions[questionIdx].id === checkForGrouping.parent
        ) {
          groupedListData = [questions[questionIdx]];
        } else {
          groupedListData = [questions[checkForGrouping.idx - 1]];
        }
      } else {
        groupedListData = [questions[questionIdx - 1]];
      }
    } else if (questionIdx === 0 && groupedQuestionData) {
      let checkForGrouping = groupedQuestionData.find((group) => {
        return group.parent === questions[questionIdx].id;
      });

      if (!!checkForGrouping) groupedListData = [questions[questionIdx]];
    }

    setGroupedList(groupedListData);

    if (questionIdx === 0) {
      setDisableDropdown(true);
    } else {
      setDisableDropdown(false);
    }

    const checkIfParent = groupedQuestionData.findIndex((group) => {
      return group.parent === questions[questionIdx].id;
    });

    if (checkIfParent > -1) {
      setDisableDropdown(true);
    } else if (questionIdx !== 0) {
      setDisableDropdown(false);
    }

    let checkGroupData = groupedQuestionData.find((group) => {
      return group.children.includes(questions[questionIdx].id);
    });

    setGroupedDataForQuestion(checkGroupData);

    if (checkGroupData) {
      setDisableDropdown(true);
    } else if (questionIdx !== 0) {
      setDisableDropdown(false);
    }
  }, [groupedQuestionData]);

  useEffect(() => {
    setSharesPassageWith(questions[questionIdx]?.shares_passage_with || -1);

    let groupedListData: question[] = [];

    if ((questionIdx !== 0 && questionIdx !==5)  && groupedPassageQuestionData) {
      let checkForGrouping = groupedPassageQuestionData.find((group) => {
        return (
          group.lastIdx === questionIdx + 1 ||
          group.children.includes(questions[questionIdx].id)
        );
      });

      if (!checkForGrouping) {
        checkForGrouping = groupedPassageQuestionData.find((group) => {
          return group.lastIdx === questionIdx;
        });
      }

      if (!!checkForGrouping) {
        if (
          sharesPassageWith === -1 &&
          questions[questionIdx].id === checkForGrouping.parent
        ) {
          groupedListData = [questions[questionIdx]];
        } else {
          groupedListData = [questions[checkForGrouping.idx - 1]];
        }
      } else if (questions[questionIdx - 1].hasPassage1) {
        groupedListData = [questions[questionIdx - 1]];
      }
    } else if (questionIdx === 0 && groupedQuestionData) {
      let checkForGrouping = groupedQuestionData.find((group) => {
        return group.parent === questions[questionIdx].id;
      });

      if (!!checkForGrouping) groupedListData = [questions[questionIdx]];
    }

    setSharePassageList(groupedListData);
    
    if (questionIdx === 0 || questionIdx === 5) {
      setDisablePassageDropdown(true);
    } else {
      setDisablePassageDropdown(false);
    }

    let checkGroupData = groupedPassageQuestionData.find((group) => {
      return group.children.includes(questions[questionIdx].id);
    });

    setGroupedPassageDataForQuestion(checkGroupData);
    
    if (checkGroupData || questionIdx === 5) {
      setDisablePassageDropdown(true);
    } else if (questionIdx !== 0) {
      setDisablePassageDropdown(false);
    }
  }, [groupedPassageQuestionData]);

  const getDomValues = () => {
    const domValues: any = {};

    keys.forEach((k) => {
      const container = document.getElementById(k);

      if (container?.innerHTML) {
        domValues[k] = container?.innerHTML;
      }
    });

    return domValues;
  };

  const saveExpressionAndMarkings = () => {
    const penMarkings = getMarkings();
    const domValues = getDomValues();

    questions[questionIdx] = {
      ...questions[questionIdx],
      // expression,
      penMarkings,
      ...domValues,
    };

    setQuestions(questions);
    setShowCalculator(false);
  };

  const nextQuestion = () => {
    saveExpressionAndMarkings();
    
    if(assessmentType==="summatives"){
    if (questionIdx < questions.length) {
      setQuestionIdx(questionIdx + 1);
    }}
    else {
        if(assesmentVariant==="A"){
          if(questionIdx<questions.length/2-1){
            setQuestionIdx(questionIdx+1)
          }
        }else {
          if(questionIdx<questions.length-1)
              setQuestionIdx(questionIdx+1)
        }
    }
  };

  const previousQuestion = () => {
    saveExpressionAndMarkings();
  if(assessmentType === "summatives")
  {
    if (questionIdx > 0) {
      setQuestionIdx(questionIdx - 1);
    }}
    else {
      if(assesmentVariant==="A"){
        if(questionIdx>0){
          setQuestionIdx(questionIdx-1);
        }
      }else {
        if(questionIdx>5){
          setQuestionIdx(questionIdx-1)
        }
      }
    }
  };

  const onChangeQuestionFromTopBar = (questionIndex: number, variant?:string, id?:number) => {
    saveExpressionAndMarkings();
    if(assessmentType === "summatives")
    setQuestionIdx(questionIndex);
    else {
      if(questions){
      const index  = questions.findIndex((ques)=>ques.id===id);
           setQuestionIdx(index);
    }}
    
  };

  const onRevert = async () => {
    if (assessmentType && id) {
      await ayncFetchQuestions(assessmentType, id);
    }

    setIsSave(false);
    setAnswerUpdated(false);
  };

  const joinArrayWithMaxElements = (array: any[], maxElements: number) => {
    if(assessmentType === "summatives") {
    if (array.length <= maxElements) {
      return array.join(", ");
    } else {
      const maxArray = array.slice(0, maxElements);
      return `${maxArray.join(", ")} ...`;
    }}else {
      if(assesmentVariant === "A"){
        if (array.length <= maxElements) {
          return array.join(", ");
        } else {
          const maxArray = array.slice(0, maxElements);
          return `${maxArray.join(", ")} ...`;
        }
      }else {
          const arrayForB = array.map((el)=>el-5);
        if (arrayForB.length <= maxElements) {
          return arrayForB.join(", ");
        } else {
          const maxArray = arrayForB.slice(0, maxElements);
          return `${maxArray.join(", ")} ...`;
        }
      }
    }
  };

  const onSave = async () => {

    const checkTextContent = (input: string)=> {
      if(input){
      //const textContent = input.replace(/<[^>]*>/g, '').trim();
      const textContent = input.replace(/<(?!img\b)[^>]*>/gi, '').trim();
      return textContent === '' ? false : true;
      }
    }

    const formatEpmtyValues = (ques: question)=>{
      return {...ques, 
                 passage_1: !ques.hasPassage1 ? null : ques.passage_1, 
                 passage_2: !ques.hasPassage2 ? null : ques.passage_2, 
                 choice_1: checkTextContent(ques.choice_1) ? ques.choice_1:"",
                 choice_2: checkTextContent(ques.choice_2) ? ques.choice_2:"",
                 choice_3: checkTextContent(ques.choice_3) ? ques.choice_3:"",
                 choice_4: checkTextContent(ques.choice_4) ? ques.choice_4:"",
                 direction: checkTextContent(ques.direction) ? ques.direction:"",
                 question: checkTextContent(ques.question) ? ques.question:"",
                }
    }

    const updatedQuestions = questions
    .filter((question) => question.updated)
    .map((ques) => formatEpmtyValues(ques));

    if (assessmentType && updatedQuestions.length) {
      const res =  await updateQuestions(updatedQuestions, assessmentType);
      if(!res){
        setIsApiError(true)
      }else{
        setQuestions(questions.map((q) => ({ ...q, updated: false })));
        setAnswerUpdated(false);
      }
    }

  };

  const toggleHasPassage = (hasPassage: boolean) => {
    const updatedQuestions = questions.map((question, index) => {
      if (
        questionIdx === index ||
        question.shares_passage_with === currentQuestion.id
      ) {
        return {
          ...question,
          passage_directions: null!,
          hasPassage1: hasPassage,
          hasPassage2: hasPassage,
          shares_passage_with: null!,
          updated: questionIdx === index ? true : question.updated,
        };
      }
      return {
        ...question,
      };
    });
    setQuestions([...updatedQuestions]);
  };

  const onSaveEditor = (text: string) => {
    onChange(editKey, text);
    setEditKey(null!);
  };

  const onAnswerChange = (choice: number) => {
    onChange("answer", choice);

    setAnswerUpdated(true);
  };
  const setIsScreenMarked = (value: boolean) => {
    if (value && !!questions.length && questions[questionIdx].isScreenMarked)
      return;

    questions[questionIdx] = {
      ...questions[questionIdx],
      isScreenMarked: value,
    };

    setQuestions([...questions]);
  };

  const onChange = async (key: string, value: any) => {
  
    if (key !== "grouped_with_question" && key !== "shares_passage_with") {
      questions[questionIdx] = {
        ...questions[questionIdx],
        [key]: value,
        updated: true,
        updated_question: true,
      };
    } else if (
      key === "grouped_with_question" ||
      key === "shares_passage_with"
    ) {
      questions[questionIdx] = {
        ...questions[questionIdx],
        [key]: value,
        updated: true,
        updated_grouping: true,
      };
    }
    if (key === "grouped_with_question") {
      await updateGrouping(questions);
    } else if (key === "shares_passage_with") {
      await updatePassageGrouping(questions);
    }
    setQuestions([...questions]);
  };

  const updateGrouping = async (questionsArray: question[]) => {
    let groupedQuestions: groupedObject[] = [];

    // eslint-disable-next-line array-callback-return
    questionsArray.map((question) => {
      if (!!question.grouped_with_question) {
        const findIndex = groupedQuestions.findIndex((groupQuestion) => {
          return groupQuestion.parent === question.grouped_with_question;
        });

        if (findIndex > -1) {
          groupedQuestions[findIndex].children.push(question.id);
          groupedQuestions[findIndex].questionNumber.push(question.questionNo);
          groupedQuestions[findIndex].lastIdx = question.questionNo;
        } else {
          groupedQuestions.push({
            parent: question.grouped_with_question,
            children: [question.grouped_with_question, question.id],
            idx: question.questionNo - 1,
            lastIdx: question.questionNo,
            questionNumber: [question.questionNo - 1, question.questionNo],
          });
        }
      }
    });

    await setGroupedQuestionData(groupedQuestions);
  };

  const removeGrouping = async (
    data: groupedObject,
    isPassage = false,
    passageRemove?: boolean
  ) => {
    let updatedQuestions: question[] = questions;

    if (isPassage && passageRemove) {
      updatedQuestions = questions.map((question, index) => {
        if (
          questionIdx === index ||
          question.shares_passage_with === currentQuestion.id
        ) {
          return {
            ...question,
            passage_directions: null!,
            // passage_1: null!,
            // passage_2: null!,
            hasPassage1: false,
            hasPassage2: false,
            shares_passage_with: null!,
            updated: questionIdx === index ? true : question.updated,
            updated_question: questionIdx === index ? true : question.updated,
          };
        }
        return {
          ...question,
        };
      });
    }

    updatedQuestions = updatedQuestions.map((question) => {
      if (data.children.includes(question.id)) {
        if (isPassage) {
          return {
            ...question,
            shares_passage_with: null!,
            updated: !question.updated
              ? true
              : question.updated_question
              ? true
              : false,
          };
        } else {
          return {
            ...question,
            grouped_with_question: null!,
            updated: !question.updated
              ? true
              : question.updated_question
              ? true
              : false,
          };
        }
      } else {
        return { ...question };
      }
    });

    setQuestions([...updatedQuestions]);

    if (isPassage) {
      await updatePassageGrouping(updatedQuestions);
    } else {
      await updateGrouping(updatedQuestions);
    }
  };

  const updatePassageGrouping = async (questionsArray: question[]) => {
    let groupedQuestions: groupedObject[] = [];

    // eslint-disable-next-line array-callback-return
    questionsArray.map((question) => {
      const checkIfQuestionHasPackage = questionsArray.find(
        (quest) =>
          quest.hasPassage1 && quest.id === question.shares_passage_with
      );
      if (!!question.shares_passage_with && checkIfQuestionHasPackage) {
        const findIndex = groupedQuestions.findIndex((groupQuestion) => {
          return groupQuestion.parent === question.shares_passage_with;
        });

        if (findIndex > -1) {
          groupedQuestions[findIndex].children.push(question.id);
          groupedQuestions[findIndex].questionNumber.push(question.questionNo);
          groupedQuestions[findIndex].lastIdx = question.questionNo;
        } else {
          groupedQuestions.push({
            parent: question.shares_passage_with,
            children: [question.shares_passage_with, question.id],
            idx: question.questionNo - 1,
            lastIdx: question.questionNo,
            questionNumber: [question.questionNo - 1, question.questionNo],
          });
        }
      }
    });
    
    await setGroupedPassageQuestionData(groupedQuestions);
  };

  const setFormaativeObjective = async (objectiveId: number) => {
    if (!id) {
      return;
    }

    await saveFormativeObjective(parseInt(id), objectiveId);

    const updatedQuestions = questions.map((question) => ({
      ...question,
      objective_id: objectiveId,
    }));
    currentQuestion.objective_id = objectiveId;
    setQuestions(updatedQuestions);
  };

  const setSummativeObjective = async (objectiveId: number) => {
    await saveSummativeObjective(currentQuestion.id, objectiveId);

    questions[questionIdx] = {
      ...questions[questionIdx],
      objective_id: objectiveId,
    };
    currentQuestion.objective_id = objectiveId;
    setQuestions([...questions]);
  };

  const onChangeObjective = (objectiveId: number) => {
    if (assessmentType === "formatives") {
      setFormaativeObjective(objectiveId);
    } else {
      setSummativeObjective(objectiveId);
    }

    setShow(false);
  };

  const hasNextQuestion = assessmentType=== "summatives"? questionIdx < questions.length - 1 : assesmentVariant==="A"? questionIdx<4 : questionIdx<9
  const hasPreviousQuestion = assessmentType === "summatives" || assesmentVariant ==="A" ?questionIdx > 0: questionIdx > 5;

  const onSpeak = (key: any) => {
    isSpeechIconClicked && setKeyToSpeak(key);
  };

  const isTestUpdated = questions.some((q) => q.updated);

  // const sharePassageList = questions.filter((q, idx) => !!q.passage_1 && idx !== questionIdx && !isNumber(q.shares_passage_with));

  const objectiveName = useMemo(() => {
    const objective = allObjectives?.find(
      (obj: { id: number }) => obj.id === currentQuestion.objective_id
    );

    return objective?.objective || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allObjectives, currentQuestion.id, currentQuestion.objective_id]);

  const updatedQuestions = useMemo(() => {
    return questions.reduce((a, b) => (b.updated ? a + 1 : a), 0);
  }, [questions]);


  const getCalculatorElement = () => {
    const elt = document.getElementById('desmos-calculator');
    return elt;
}

const destroyCalculator = () => {
    const elt = getCalculatorElement();
    if(!elt) return;
    const calculator = getCalculator();
    calculator && calculator.destroy();
    setCalculator(null);
    elt.style.display = 'none';
}

const closeCalculator = () => {
  setShowCalculator(false);
  destroyCalculator();
}





  const hasPassage = currentQuestion.hasPassage1;
  const showTabs = currentQuestion.hasPassage2;

  return (
    <QuestionnaireContext.Provider
      value={{
        audioRef,
        destroyCalculator,
        getCalculatorElement, 
        closeCalculator,
        onSpeak,
        keys,
        isSpeechIconClicked,
        setIsSpeechIconClicked,
        keyToSpeak,
        setKeyToSpeak,
        setIsPencilActive,
        isPencilActive,
        showCalculator,
        setShowCalculator,
        isHighlighterActive,
        setIsHighlighterActive,
        showBackModal,
        setBackModal,
        hasPassage,
        showTabs,
        show,
        setShow,
        questionIdx,
        setQuestionIdx,
        currentQuestion,
        questions,
        nextQuestion,
        previousQuestion,
        hasNextQuestion,
        hasPreviousQuestion,
        isTestUpdated,
        onRevert,
        onSave,
        sharePassageList,
        toggleHasPassage,
        editKey,
        setEditKey,
        onSaveEditor,
        onChange,
        tabIndex,
        setTabIndex,
        objectiveName,
        onChangeObjective,
        showSaveModel,
        setShowSaveModel,
        answerUpdated,
        onAnswerChange,
        updatedQuestions,
        isPreview,
        setPreview,
        setIsSave,
        isSave,
        onChangeQuestionFromTopBar,
        setIsScreenMarked,
        containerRef,
        groupedDataForQuestion,
        groupedList,
        disableDropdown,
        removeGrouping,
        groupedWithQuestion,
        groupedPassageDataForQuestion,
        joinArrayWithMaxElements,
        disablePassageDropdown,
        setShowGroupRemoveWarningModal,
        showGroupRemoveWarningModal,
        setTriggerPassageRemoveGroupingFor,
        triggerPassageRemoveGroupingFor,
        grade,
        sharesPassageWith,
        setShowHighlightEraser,
        showHighlightEraser,
        setIsExpand,
        isExpand,
        assesmentVariant, 
        setAssesmentVariant,
        variants, 
        isApiError, 
        setIsApiError
        
      }}
    >
      {props.children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireContextProvider;
